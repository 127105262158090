import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { API_URL } from '../utils/constants';

// Define types
interface RegisterData {
  email: string;
  username: string;
  password: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
}

interface LoginData {
  username: string;
  password: string;
}

// Create axios instance
const api = axios.create({
  baseURL: API_URL,
});

// Add request interceptor to add token to requests
api.interceptors.request.use(
  async (config) => {
    const token = await AsyncStorage.getItem('accessToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add response interceptor to handle expired tokens
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    
    // If error is 401 and we haven't tried refreshing token yet
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      
      try {
        const refreshToken = await AsyncStorage.getItem('refreshToken');
        
        if (!refreshToken) {
          // No refresh token, user must login again
          return Promise.reject(error);
        }
        
        // Try to refresh the token
        const response = await axios.post(`${API_URL}/auth/refresh-token`, {
          refreshToken,
        });
        
        // Save new tokens
        const { accessToken, refreshToken: newRefreshToken } = response.data.tokens;
        await AsyncStorage.setItem('accessToken', accessToken);
        await AsyncStorage.setItem('refreshToken', newRefreshToken);
        
        // Retry the original request with the new token
        originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
        return api(originalRequest);
      } catch (refreshError) {
        // If refresh fails, clear tokens and return to login
        await AsyncStorage.removeItem('accessToken');
        await AsyncStorage.removeItem('refreshToken');
        return Promise.reject(refreshError);
      }
    }
    
    return Promise.reject(error);
  }
);

export const authService = {
  // Helper method to get the API URL for debugging
  getApiUrl: () => API_URL,
  
  // Register new user
  register: async (userData: RegisterData) => {
    try {
      console.log(`Making request to ${API_URL}/auth/register`);
      const response = await api.post('/auth/register', userData);
      
      // Check if we got HTML instead of JSON (common API Gateway misconfiguration)
      if (response.headers['content-type']?.includes('text/html')) {
        console.error('Received HTML response instead of JSON');
        throw new Error('Invalid API response format - received HTML instead of JSON');
      }
      
      return response.data;
    } catch (error) {
      console.error('Error in register API call:', error);
      throw error;
    }
  },
  
  // Login user
  login: async (username: string, password: string) => {
    const response = await api.post('/auth/login', { username, password });
    return response.data;
  },
  
  // Logout user
  logout: async () => {
    const response = await api.post('/auth/logout');
    return response.data;
  },
  
  // Request password reset
  forgotPassword: async (email: string) => {
    const response = await api.post('/auth/forgot-password', { email });
    return response.data;
  },
  
  // Reset password with token
  resetPassword: async (token: string, newPassword: string) => {
    const response = await api.post('/auth/reset-password', {
      token,
      newPassword,
    });
    return response.data;
  },
  
  // Verify email with token
  verifyEmail: async (token: string) => {
    const response = await api.post('/auth/verify-email', { token });
    return response.data;
  },
  
  // Check if token is valid
  verifyToken: async () => {
    const response = await api.post('/auth/verify-token');
    return response.data;
  },
}; 