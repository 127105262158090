import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, ScrollView } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { InputField } from '../components/InputField';
import { Button } from '../components/Button';
import { useAuth } from '../hooks/useAuth';
import { userService } from '../services/userService';
import { VALIDATION } from '../utils/constants';

export default function Profile() {
  const navigate = useNavigate();
  const { user, logout, updateProfile, isLoading } = useAuth();
  
  const [profileData, setProfileData] = useState({
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
    phoneNumber: '',
  });
  
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [generalError, setGeneralError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [activity, setActivity] = useState<any[]>([]);
  const [isUpdating, setIsUpdating] = useState(false);
  
  // Fetch user activity
  useEffect(() => {
    const fetchActivity = async () => {
      try {
        const data = await userService.getActivity();
        setActivity(data.activity || []);
      } catch (error) {
        console.error('Failed to fetch activity:', error);
      }
    };
    
    fetchActivity();
  }, []);
  
  const handleChange = (field: string, value: string) => {
    setProfileData(prev => ({ ...prev, [field]: value }));
  };
  
  const validateForm = () => {
    const newErrors: Record<string, string> = {};
    
    // Validate first name and last name length if provided
    if (profileData.firstName && profileData.firstName.length > VALIDATION.NAME_MAX_LENGTH) {
      newErrors.firstName = `First name cannot exceed ${VALIDATION.NAME_MAX_LENGTH} characters`;
    }
    
    if (profileData.lastName && profileData.lastName.length > VALIDATION.NAME_MAX_LENGTH) {
      newErrors.lastName = `Last name cannot exceed ${VALIDATION.NAME_MAX_LENGTH} characters`;
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const handleUpdateProfile = async () => {
    setGeneralError('');
    setSuccessMessage('');
    
    if (!validateForm()) {
      return;
    }
    
    setIsUpdating(true);
    try {
      await updateProfile({
        firstName: profileData.firstName,
        lastName: profileData.lastName,
        phoneNumber: profileData.phoneNumber,
      });
      
      setSuccessMessage('Profile updated successfully');
    } catch (error: any) {
      setGeneralError(error.response?.data?.error?.message || 'Failed to update profile. Please try again.');
    } finally {
      setIsUpdating(false);
    }
  };
  
  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      setGeneralError('Failed to logout. Please try again.');
    }
  };
  
  // Format date for activity display
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };
  
  if (!user) {
    return (
      <View style={styles.container}>
        <Text>Loading profile...</Text>
      </View>
    );
  }
  
  return (
    <ScrollView style={styles.scrollView}>
      <View style={styles.container}>
        <View style={styles.header}>
          <Text style={styles.greeting}>Hello, {user.username}!</Text>
          <TouchableOpacity style={styles.logoutButton} onPress={handleLogout}>
            <Text style={styles.logoutText}>Logout</Text>
          </TouchableOpacity>
        </View>
        
        <View style={styles.card}>
          <Text style={styles.sectionTitle}>Profile Information</Text>
          
          {generalError ? (
            <Text style={styles.errorMessage}>{generalError}</Text>
          ) : null}
          
          {successMessage ? (
            <Text style={styles.successMessage}>{successMessage}</Text>
          ) : null}
          
          <View style={styles.nameRow}>
            <View style={styles.nameField}>
              <InputField
                label="First Name"
                value={profileData.firstName}
                onChangeText={(text) => handleChange('firstName', text)}
                placeholder="First name"
                error={errors.firstName}
              />
            </View>
            
            <View style={styles.nameField}>
              <InputField
                label="Last Name"
                value={profileData.lastName}
                onChangeText={(text) => handleChange('lastName', text)}
                placeholder="Last name"
                error={errors.lastName}
              />
            </View>
          </View>
          
          <InputField
            label="Email"
            value={profileData.email}
            onChangeText={() => {}}
            placeholder="Email"
            keyboardType="email-address"
            autoCapitalize="none"
            // Email cannot be changed, so disable it
            // We would need a separate flow for email changes with verification
          />
          
          <InputField
            label="Phone Number"
            value={profileData.phoneNumber}
            onChangeText={(text) => handleChange('phoneNumber', text)}
            placeholder="Enter your phone number"
            keyboardType="phone-pad"
            error={errors.phoneNumber}
          />
          
          <Button
            title="Update Profile"
            onPress={handleUpdateProfile}
            isLoading={isUpdating}
            fullWidth
          />
        </View>
        
        <View style={styles.card}>
          <Text style={styles.sectionTitle}>User Information</Text>
          
          <View style={styles.infoRow}>
            <Text style={styles.infoLabel}>Username:</Text>
            <Text style={styles.infoValue}>{user.username}</Text>
          </View>
          
          <View style={styles.infoRow}>
            <Text style={styles.infoLabel}>User ID:</Text>
            <Text style={styles.infoValue}>{user.id}</Text>
          </View>
          
          <View style={styles.infoRow}>
            <Text style={styles.infoLabel}>Roles:</Text>
            <Text style={styles.infoValue}>{user.roles?.join(', ') || 'No roles assigned'}</Text>
          </View>
        </View>
        
        <View style={styles.card}>
          <Text style={styles.sectionTitle}>Recent Activity</Text>
          
          {activity.length > 0 ? (
            activity.map((item, index) => (
              <View key={index} style={styles.activityItem}>
                <Text style={styles.activityText}>{item.type}</Text>
                <Text style={styles.activityDate}>{formatDate(item.timestamp)}</Text>
              </View>
            ))
          ) : (
            <Text style={styles.noActivityText}>No recent activity</Text>
          )}
        </View>
        
        <View style={styles.buttonContainer}>
          <Button
            title="Change Password"
            onPress={() => navigate('/change-password')}
            variant="outline"
            fullWidth
          />
        </View>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  scrollView: {
    backgroundColor: '#F3F4F6',
  },
  container: {
    flex: 1,
    padding: 20,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
  greeting: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#111827',
  },
  logoutButton: {
    padding: 8,
  },
  logoutText: {
    color: '#EF4444',
    fontWeight: '600',
  },
  card: {
    backgroundColor: 'white',
    borderRadius: 8,
    padding: 24,
    marginBottom: 20,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 2,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 16,
    color: '#111827',
  },
  errorMessage: {
    color: '#EF4444',
    backgroundColor: '#FEE2E2',
    padding: 12,
    borderRadius: 6,
    marginBottom: 16,
    textAlign: 'center',
  },
  successMessage: {
    color: '#047857',
    backgroundColor: '#D1FAE5',
    padding: 12,
    borderRadius: 6,
    marginBottom: 16,
    textAlign: 'center',
  },
  nameRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  nameField: {
    flex: 1,
    marginRight: 8,
  },
  infoRow: {
    flexDirection: 'row',
    marginBottom: 12,
  },
  infoLabel: {
    width: 100,
    fontWeight: '600',
    color: '#4B5563',
  },
  infoValue: {
    flex: 1,
    color: '#1F2937',
  },
  activityItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderBottomColor: '#E5E7EB',
  },
  activityText: {
    color: '#1F2937',
  },
  activityDate: {
    color: '#6B7280',
    fontSize: 12,
  },
  noActivityText: {
    color: '#6B7280',
    fontStyle: 'italic',
    textAlign: 'center',
    paddingVertical: 16,
  },
  buttonContainer: {
    marginBottom: 40,
  },
}); 