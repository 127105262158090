import React, { useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, ScrollView } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { InputField } from '../components/InputField';
import { Button } from '../components/Button';
import { useAuth } from '../hooks/useAuth';
import { VALIDATION } from '../utils/constants';

export default function Register() {
  const navigate = useNavigate();
  const { register, isLoading } = useAuth();
  
  const [formData, setFormData] = useState({
    email: '',
    username: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
  });
  
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [generalError, setGeneralError] = useState('');

  const handleChange = (field: string, value: string) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const validateForm = () => {
    const newErrors: Record<string, string> = {};
    
    // Validate email
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!VALIDATION.EMAIL_REGEX.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address';
    }
    
    // Validate username
    if (!formData.username.trim()) {
      newErrors.username = 'Username is required';
    } else if (formData.username.length < VALIDATION.USERNAME_MIN_LENGTH) {
      newErrors.username = `Username must be at least ${VALIDATION.USERNAME_MIN_LENGTH} characters`;
    } else if (formData.username.length > VALIDATION.USERNAME_MAX_LENGTH) {
      newErrors.username = `Username cannot exceed ${VALIDATION.USERNAME_MAX_LENGTH} characters`;
    } else if (!VALIDATION.USERNAME_REGEX.test(formData.username)) {
      newErrors.username = 'Username can only contain letters, numbers, and underscores';
    }
    
    // Validate password
    if (!formData.password) {
      newErrors.password = 'Password is required';
    } else if (formData.password.length < VALIDATION.PASSWORD_MIN_LENGTH) {
      newErrors.password = `Password must be at least ${VALIDATION.PASSWORD_MIN_LENGTH} characters`;
    }
    
    // Validate password confirmation
    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
    }
    
    // Validate first name and last name length if provided
    if (formData.firstName && formData.firstName.length > VALIDATION.NAME_MAX_LENGTH) {
      newErrors.firstName = `First name cannot exceed ${VALIDATION.NAME_MAX_LENGTH} characters`;
    }
    
    if (formData.lastName && formData.lastName.length > VALIDATION.NAME_MAX_LENGTH) {
      newErrors.lastName = `Last name cannot exceed ${VALIDATION.NAME_MAX_LENGTH} characters`;
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRegister = async () => {
    setGeneralError('');
    
    if (!validateForm()) {
      return;
    }
    
    try {
      // Exclude confirmPassword from registration data
      const { confirmPassword, ...registrationData } = formData;
      
      await register(registrationData);
      navigate('/profile');
    } catch (error: any) {
      console.error('Registration error in component:', error);
      let errorMessage = 'Registration failed. Please try again.';
      
      if (error.message === 'Invalid API response format - received HTML instead of JSON') {
        errorMessage = 'The server is not properly configured. Please contact support.';
      } else if (error.response?.data?.error?.message) {
        errorMessage = error.response.data.error.message;
      }
      
      setGeneralError(errorMessage);
    }
  };

  return (
    <ScrollView style={styles.scrollView} contentContainerStyle={styles.scrollViewContent}>
      <View style={styles.container}>
        <View style={styles.card}>
          <Text style={styles.title}>Create an account</Text>
          
          {generalError ? (
            <Text style={styles.generalError}>{generalError}</Text>
          ) : null}
          
          <InputField
            label="Email"
            value={formData.email}
            onChangeText={(text) => handleChange('email', text)}
            placeholder="Enter your email"
            keyboardType="email-address"
            error={errors.email}
            autoCapitalize="none"
          />
          
          <InputField
            label="Username"
            value={formData.username}
            onChangeText={(text) => handleChange('username', text)}
            placeholder="Choose a username"
            error={errors.username}
            autoCapitalize="none"
          />
          
          <View style={styles.nameRow}>
            <View style={styles.nameField}>
              <InputField
                label="First Name"
                value={formData.firstName}
                onChangeText={(text) => handleChange('firstName', text)}
                placeholder="First name"
                error={errors.firstName}
              />
            </View>
            
            <View style={styles.nameField}>
              <InputField
                label="Last Name"
                value={formData.lastName}
                onChangeText={(text) => handleChange('lastName', text)}
                placeholder="Last name"
                error={errors.lastName}
              />
            </View>
          </View>
          
          <InputField
            label="Phone Number"
            value={formData.phoneNumber}
            onChangeText={(text) => handleChange('phoneNumber', text)}
            placeholder="Enter your phone number (optional)"
            keyboardType="phone-pad"
            error={errors.phoneNumber}
          />
          
          <InputField
            label="Password"
            value={formData.password}
            onChangeText={(text) => handleChange('password', text)}
            placeholder="Create a password"
            secureTextEntry
            error={errors.password}
          />
          
          <InputField
            label="Confirm Password"
            value={formData.confirmPassword}
            onChangeText={(text) => handleChange('confirmPassword', text)}
            placeholder="Confirm your password"
            secureTextEntry
            error={errors.confirmPassword}
          />
          
          <Button
            title="Sign up"
            onPress={handleRegister}
            isLoading={isLoading}
            fullWidth
          />
          
          <View style={styles.footer}>
            <Text style={styles.footerText}>Already have an account?</Text>
            <TouchableOpacity onPress={() => navigate('/login')}>
              <Text style={styles.footerLink}>Log in</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  scrollView: {
    backgroundColor: '#F3F4F6',
  },
  scrollViewContent: {
    flexGrow: 1,
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  card: {
    backgroundColor: 'white',
    borderRadius: 8,
    padding: 24,
    width: '100%',
    maxWidth: 500,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 2,
    marginVertical: 24,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 24,
    textAlign: 'center',
    color: '#111827',
  },
  generalError: {
    color: '#EF4444',
    backgroundColor: '#FEE2E2',
    padding: 12,
    borderRadius: 6,
    marginBottom: 16,
    textAlign: 'center',
  },
  nameRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  nameField: {
    flex: 1,
    marginRight: 8,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 24,
  },
  footerText: {
    color: '#6B7280',
    marginRight: 4,
  },
  footerLink: {
    color: '#2563EB',
    fontWeight: '600',
  },
}); 