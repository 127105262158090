import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { API_URL } from '../utils/constants';

// Define types
interface User {
  id: string;
  email: string;
  username: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  roles: string[];
}

interface ProfileUpdateData {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
}

// Create axios instance with the same interceptors from authService
const api = axios.create({
  baseURL: API_URL,
});

// Add request interceptor to add token to requests
api.interceptors.request.use(
  async (config) => {
    const token = await AsyncStorage.getItem('accessToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const userService = {
  // Get current user profile
  getCurrentUser: async (): Promise<User> => {
    const response = await api.get('/users/me');
    return response.data;
  },
  
  // Update user profile
  updateProfile: async (userData: ProfileUpdateData): Promise<User> => {
    const response = await api.put('/users/me', userData);
    return response.data;
  },
  
  // Change password
  changePassword: async (currentPassword: string, newPassword: string): Promise<any> => {
    const response = await api.post('/users/change-password', {
      currentPassword,
      newPassword,
    });
    return response.data;
  },
  
  // Get user activity
  getActivity: async (): Promise<{ activity: any[] }> => {
    const response = await api.get('/users/activity');
    return response.data;
  },
}; 