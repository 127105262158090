// API URL - use environment variable or direct URL to production API gateway
export const API_URL = process.env.REACT_APP_API_URL as string || 'https://english-everyday.com/biztools/api';

// Token constants
export const TOKEN_EXPIRY_BUFFER = 60000; // 60 seconds buffer before token expires

// Validation constants
export const VALIDATION = {
  PASSWORD_MIN_LENGTH: 8,
  USERNAME_MIN_LENGTH: 3,
  USERNAME_MAX_LENGTH: 20,
  USERNAME_REGEX: /^[a-zA-Z0-9_]+$/, // Only allow alphanumeric and underscore
  EMAIL_REGEX: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  NAME_MAX_LENGTH: 50,
}; 